<template>
  <el-form ref="fixRateForm" :model="detailFrom" :rules="rules">
    <div v-for="(item, index) in detailFrom.detail" :key="index">
      <el-row type="flex" align="middle">
        <el-col :span="10">
          <el-form-item
            :prop="'detail.' + index + '.targetCompanyId'"
            :rules="{ validator: validateUnique, trigger: 'change' }"
          >
            <le-company-under-select
              v-model="item.targetCompanyId"
              :label="'收益方' + (index + 1)"
              :yunst="true"
              large
            ></le-company-under-select>
          </el-form-item>
        </el-col>
        <el-col :span="11">
          <el-form-item
            :prop="'detail.' + index + '.rate'"
            :rules="{ validator: validateRate, trigger: 'change' }"
          >
            <div class="a-flex-rfsc">
              <span style="width: 130px; text-align: end">分成比例</span>
              <el-input
                v-model="item.rate"
                placeholder="请输入分成比例"
                type="number"
                min="0"
                large
                style="width: 350px; margin-left: 20px"
              >
                <template slot="append">%</template>
              </el-input>
              <i
                v-if="detailFrom.detail.length > 1"
                class="el-icon-remove-outline a-c-red a-fs-16 a-ml-15"
                @click="handlerDelFixedIncome(index)"
              ></i>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div class="a-flex-rfsc a-mt-10 addFixedIncom" @click="handlerAddFixedIncome">
      <i class="el-icon-circle-plus-outline a-c-blue a-fs-16"></i>
      <span class="a-ml-03 a-c-blue">添加</span>
    </div>
  </el-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    value: {
      type: Array,
    },
    incomeId: {
      type: Number,
    },
  },

  data() {
    return {
      detailFrom: {
        detail: [],
      },
      rules: {},
    };
  },
  computed: {
    ...mapState({
      company: (state) => state.company.company,
    }),
  },

  watch: {
    value: {
      handler(val) {
        if (val) {
          this.detailFrom.detail = val;
        }
      },
      deep: true,
      immediate: true,
    },

    incomeId: {
      handler(val) {
        if (val) {
          this.getIncomeDetail();
        } else {
          this.handlerAddFixedIncome();
        }
      },
      immediate: true,
    },

    "detailFrom.detail": {
      handler(val) {
        this.$emit("input", val);
      },
      deep: true,
    },
  },

  methods: {
    /**
     * 获取项目详情
     */
    getIncomeDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.gitEditIncomeDetailInfo,
          params: {
            incomeId: this.incomeId,
          },
        })
        .then(({ data }) => {
          this.detailFrom.detail = data.targetList.map((item) => {
            return {
              targetCompanyId: item.targetCompanyId,
              rate: item.rate / 100,
              serNo: item.serNo,
            };
          });
        });
    },
    /**
     * 添加固定收益条目
     */
    handlerAddFixedIncome() {
      /**
       * 计算最大序号条目
       */
      let maxSerNo = 0;
      this.detailFrom.detail.map((item) => {
        if (item.serNo >= maxSerNo) {
          maxSerNo = item.serNo;
        }
      });
      const rateDetai = {
        targetCompanyId: null,
        rate: 0,
        serNo: maxSerNo + 1,
      };
      this.detailFrom.detail.push(rateDetai);
    },

    handlerDelFixedIncome(index) {
      this.detailFrom.detail.splice(index, 1);
    },

    validateUnique(rule, value, callback) {
      if (!value) {
        return callback(new Error("请选择收益方"));
      }
      const list = this.detailFrom.detail.map((item) => {
        return item.targetCompanyId;
      });
      if (list.indexOf(value) != list.lastIndexOf(value)) {
        return callback(new Error("选择的收益方已存在"));
      }
      return callback();
    },

    validateRate(rule, value, callback) {
      if (!(value + "").match(/^\d{1,3}(\.\d{1,2})?$/) || value < 0) {
        return callback(new Error("请输入正确数值,最大100,最小0,小数点后最多2位"));
      }
      const totalRate = this.detailFrom.detail
        .map((item) => {
          return item.rate;
        })
        .reduce(function (prev, cur) {
          return Number(prev || 0) + Number(cur || 0);
        });
      if (totalRate > 100) {
        return callback(new Error("设置的分成总比例" + totalRate + "%不能超过最大可分配比例100%"));
      } else if (totalRate < 100) {
        return callback(new Error("设置的收益方分成比例总和应为100%，请检查后提交"));
      } else {
        this.$refs.fixRateForm.clearValidate();
        return callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.addFixedIncom {
  display: inline;
  margin-left: 154px;
  cursor: pointer;
}
</style>
